body {
  background-color: rgb(21,26,44);
}
.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .rotate {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: rgb(82,84,92);
  background: linear-gradient(90deg, rgba(82,84,92,1) 0%, rgba(38,44,63,1) 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 10px;
  height: 5%;
}
.App-body {
  background-color: rgb(21,26,44);
  flex-direction: column; display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 1%;
}
.card{
  background-color: rgb(38,44,63);
  margin: 0 auto ;
  width: 50%;
  color: white;
}
.dropDownCard{
  padding: 2%;
}
.serverGridElements{
  margin-bottom: 1%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  vertical-align: top;
  font-size: 8pt;
  text-align: left;
}
.tableValue {
  font-weight: bold;
  width: 100px;
}
.tableLabel {
  width: 100px;
  text-align: left;
}
.mainContainer {
  text-align: center;
}

.playerAvatar {
  position: relative;
}
.playerAvatarDetails {
  padding-top: 20px;
  text-align: left;
  font-size: 12pt;
}

.topRow {
  display: flex;
}
.buttonHolder {
  line-height: 10px;
  display: inline-block;
  width: 100%;
}
.visualisationCanvas
{
float: right;
background-color: rgb(38,44,63);
margin-bottom: 10px;
}
.selectedAvatarDisplay{
  float: left;
  font-size: 14pt;
 padding-left: 180px;
}
.lastMovementData
{
  float: right;
}

input {
  width: 300px;
}
.cntrlBtn {
  padding: 8%;
  background-color: rgb(76,156,185);
}
.row1{
  width: 60%;
}
.row2{
  margin-top: 5%;
  width: 60%;
}
.customInput {
  padding: 0.5rem 1.5rem;
  border: double 2px transparent;
  border-radius: 50px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgb(108, 112, 117);
  color: white;
  margin-bottom: 20px;
  width: 90%;
}
.customButton {
  margin-top: 0;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white;
  background: rgb(72, 72, 121);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: large;
}
.customButton:active {
  background: rgb(35, 35, 65); 
  transform: scale(0.98); 
}
.customButton:hover,
.statsButton:hover,
.party-btn:hover{
  background: rgb(98, 98, 131);
}

.serverDetails{
  text-align: left;
  padding: 10px;
}
.statsButton{
  position: relative;
  left: 80%;
  margin-top: 0;
  padding: 0.4em 0.7em;
  border: none;
  outline: none;
  color: white;
  background: rgb(72, 72, 121);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: small;
}
.party-btn{
  position: relative;
  margin-top: 0;
  margin-right: 5px;
  padding: 0.4em 0.7em;
  border: none;
  outline: none;
  color: white;
  background: rgb(72, 72, 121);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: small;
}
.disabled-btn{
  position: relative;
  margin-top: 0;
  margin-right: 5px;
  padding: 0.4em 0.7em;
  border: none;
  outline: none;
  color: rgb(172, 158, 199);
  background: rgb(127, 127, 170);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: small;
}
.party-cntrl-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; 
  width: 100%; 
  max-width: 200px; 
  padding: 10px;
  box-sizing: border-box;
}

.consoleBtn{
  margin: auto;
}
.showButton{
  left:35%;
}
.chartButton{
  background-color: transparent;
  color: white;
  margin-left: 3%;
}
.dataField{
  color:#F4BA41 ;
}

.chat{
  background-color:rgb(38,44,63) ;
  width: 80%;
  height: 95%;
  padding: 1%;
}
ul{
  list-style-type: none;
}
.chat-bubble{
  color: white;
  padding: 10px;
  text-decoration: none;
  text-align: left;
  width: 20%;
  border-radius: 5px;
}

.bubble-timestamp{
  margin-right: auto;
  color: white;
  margin-bottom: 15px;
}

.user-chat-bubble{
  width: 15%;
  color: black;
  padding:10px;
  text-decoration: none;
  margin-left: 8%;
  border-radius: 5px;
}
.unity-chat-bubble{
  text-align: left;
  width: 100%;
  color: white;
  font-size: large;
  padding: 2%;
  margin: 1%;
  text-decoration: none;
  margin-left: auto;
  border-radius: 5px;
}
.user-bubble-timestamp{
  margin-left: 18%;
  color: white;
  margin-bottom: 15px;
}

.chat-input{
  display: flex;
  align-items: center;
  margin: 1% auto;
  width: 100%;
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.emoji-picker{
  position: absolute;
  top: 5%;
  right: 10%;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.chat-container::-webkit-scrollbar,
.party-people-modal-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-container,
.party-people-modal-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.chat-container{
  width: 98vw;
  overflow-y: scroll;
  text-align: left;
  height: 22vh;
  margin: auto;

}

.chatText{
  color: white;
}

.party-people-modal-list{
  max-height: 800px;
  overflow-y: scroll;
}